import { IFeatures } from "@services/api/feature/feature.interface";
import { IStoreSidebarMenu } from "@store/interfaces/storeSidebarMenu.interface";

const storePlayerState = {
	state: {
		sidebarMenu: null,
	},
	mutations: {
		SET_SIDEBARMENU: (state: IStoreSidebarMenu, sidebarMenu: IFeatures) => {
			state.sidebarMenu = sidebarMenu;
		},
	},
	actions: {
		setSidebarMenu: ({ commit }, sidebarMenu: IFeatures) =>
			commit('SET_SIDEBARMENU', sidebarMenu),
	},
	modules: {},
};

export default storePlayerState;
