import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue';

// STORES
import storeApp from './modules/app';
import storeSidebarMenu from './modules/sidebarMenu';
import storeAuth from './modules/storeAuth';
import storeMusicTable from './modules/storeMusicTable';
import storeSchedule from './modules/storeSchedule';
import storePlayerState from './modules/player';

// INTERFACES
import { IStoreAuth } from './interfaces/storeAuth.interface';
import { IStoreMusicTable } from './interfaces/storeMusicTable.interface';
import { IStoreSchedule } from './interfaces/storeSchedule.interface';
import { IStoreApp } from './interfaces/storeApp.type';
import { IStorePlayerState } from './interfaces/storePlayerState.interface';
import { IStoreSidebarMenu } from './interfaces/storeSidebarMenu.interface';

export interface State {
  app: IStoreApp;
	sidebarMenu: IStoreSidebarMenu;
	playerState: IStorePlayerState;
  auth: IStoreAuth;
  musicTable: IStoreMusicTable;
  schedule: IStoreSchedule;
}

export const key: InjectionKey<Store<State>> = Symbol('');

// Стор для хранения инфы состоит из модулей, которые находятся
// в соответствующих файлах
export default createStore<State>({
	modules: {
		app: storeApp,
		sidebarMenu: storeSidebarMenu,
		playerState: storePlayerState,
		auth: storeAuth,
		musicTable: storeMusicTable,
		schedule: storeSchedule,
	},
});
