import { ENV } from '@utils/innerENV';

export interface IMenuItem {
  label: string;
  icon: string;
  to: string;
  serverName: AvailablePages;
}

export type AvailablePages = 'music' | 'education' | 'events';

export interface IMenu {
  icon: string;
  items: IMenuItem[];
}


export const rawMenu: IMenu = {
	icon: 'pi pi-fw pi-home',
	items: [
		{ label: ENV.EDUCATION_LABEL, icon: 'fa-regular fa-calendar', to: ENV.EDUCATION_URL, serverName: 'education', },
		{ label: 'Медиатека', icon: 'fa-brands fa-itunes-note', to: '/dashboard', serverName: 'music' },
		{ label: 'События', icon: 'fa-brands fa-itunes-note', to: '/events', serverName: 'events' },
	],
};

