import { RouteRecordRaw } from 'vue-router';

const ResetPassword = () => import('@pages/Auth/ResetPassword.vue');
const MainLayout = () => import('@layouts/MainLayout.vue');
const MainPage = () => import('@pages/Main.vue');
const LoginNew = () => import('@pages/Auth/AuthPage.vue');
const RegisterNew = () => import('@pages/Auth/RegisterPage.vue');

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: MainLayout,
		children: [
			{ path: '', component: MainPage, } ,
			{ path: '/auth', component: LoginNew },
			{ path: '/signup', component: RegisterNew },
			{ path: '/recovery', component: ResetPassword },
		]
	}
];

/**
 * Здесь содержатся массив публичных роутов, которые может
 * посетить неавторизованный пользователь.
 *
 */
const publicRoutes: RouteRecordRaw[] = routes.map((route) => {
	const meta = {
		public: true,
	};
	return { ...route, meta };
});

export default publicRoutes;
