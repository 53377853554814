import axios, { AxiosResponse } from 'axios';
import { URL } from '@utils/innerENV';
import { IFeatures } from './feature.interface';

const { ENV_URL } = URL;

const getFeatures = async () => {
	try {
		const {
			data: { message: featuresFromServer },
		}: AxiosResponse<{ message: IFeatures }> = await axios.get(`${ENV_URL}/api/feature`);
		return featuresFromServer;
	} catch (e) {
		throw new Error(e);
	}
};

export {
	getFeatures,
};