import axios, { AxiosResponse } from 'axios';
import { IEvent } from '@interfaces/Models/Event.interface';
import handleError from '../../utils/errorHandler';
import { URL } from '@utils/innerENV';
import { IAddEvent } from './DTO/addEvent.dto';

const { ENV_URL } = URL;

const getEvents = async () => {
	try {
		const {
			data: { message: events },
		}: AxiosResponse<{ message: IEvent[] }> = await axios.get(`${ENV_URL}/api/event/all`);
		return events;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

const addEvent = async ({ name, date }: IAddEvent) => {
	try {
		//TODO: УБРАТЬ ЗАХАРДКОЖЕННЫЙ ПЛЕЙС
		const { data } = await axios.post(`${ENV_URL}/api/event/create`, {
			name,
			date,
			place: 'any string',
		});
		console.log(data);
	} catch (e) {
		throw new Error(handleError(e));
	}
};

export { getEvents, addEvent };
