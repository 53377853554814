import { RolesKeys } from "@services/roles";
import { checkRole, getUser } from "@services/api/authHandler";
import { IStoreAuth } from '../interfaces/storeAuth.interface';

const storeAuth = {
	state: {
		authenticated: false,
		loading: false,
		error: '',
		user: '',
		role: 'notUser',
	},
	mutations: {
		SET_AUTH: (state: IStoreAuth, auth: boolean) => {
			state.authenticated = auth;
		},
		SET_LOADING: (state: IStoreAuth, loading: boolean) => {
			state.loading = loading;
		},
		SET_ERROR: (state: IStoreAuth, error: string) => {
			state.error = error;
		},
		SET_USER: (state: IStoreAuth, user: string) => {
			state.user = user;
		},
		SET_ROLE: (state: IStoreAuth, role: RolesKeys) => {
			state.role = role;
		},
		SET_RESET_AUTH: (state: IStoreAuth) => {
			state.authenticated = false;
			state.user = '';
			state.role = 'notUser';
		},
	},
	actions: {
		setAuth: ({ commit }, auth: boolean) => commit('SET_AUTH', auth),
		setError: ({ commit }, error: string) => commit('SET_ERROR', error),
		setUser: ({ commit }, user: string) => commit('SET_USER', user),
		setLoading: ({ commit }, loading: boolean) => commit('SET_LOADING', loading),
		setResetAuth: ({ commit }) => commit('SET_RESET_AUTH'),
		setRole: ({ commit }) => commit('SET_ROLE'),
		initAuth: ({ commit }, { username, role }: { username: string; role: RolesKeys }) => {
			commit('SET_AUTH', true);
			commit('SET_USER', username);
			commit('SET_ROLE', role);
		},
		checkAuth: async ({ dispatch, commit }) => {
			try {
				const { data } = await getUser();
				if (data) {
					try {
						await checkRole();
						dispatch('initAuth', { username: data.name, role: 'admin' });
					} catch (e: unknown) {
						dispatch('initAuth', { username: data.name, role: 'user' });
					}
				}
			} catch (e) {
				commit('SET_RESET_AUTH');
			}
		},
	},
	modules: {},
};

export default storeAuth;
