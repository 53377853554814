export const error_page_ru = {
	homePage: 'Вернуться на главную страницу',
	notFound: 'Страница не найдена',
	notFoundSummary: 'К сожалению, запрашиваемая вами страница не найдена.',
} as const;

export const error_page_en = {
	homePage: 'Go to home page',
	notFound: 'Page not found',
	notFoundSummary: 'Unfortunately, the page you requested was not found.',
} as const;