interface IErrors {
  [k: string]: string;
}

const errors: IErrors = {
	'Email already exists': 'Email уже существует',
	'invalid email/password': 'Неверный логин или пароль',
	'Request failed with status code 304': 'Не изменено'
};

const errorsDict: IErrors = {
	'Пользователь с таким email уже существует': 'infoMessages.emailExists',
	'Неверный логин или пароль': 'infoMessages.loginFailed',
	'Введен неверный email': 'infoMessages.WrongEmail',
	'Письмо отправлено на ваш email. Проверьте почту': 'infoMessages.newPasswordSent',
	'Письмо отправлено на ваш email.': 'infoMessages.newPasswordSent',
};

export { errors, errorsDict };
