import axios, { AxiosResponse } from 'axios';
import handleError from '../../../utils/errorHandler';
import { URL } from '@utils/innerENV';
import { ISong } from '@interfaces/Models/Song.interface';
import { IArtist } from '@interfaces/Models/Artist.interface';
import { IAddTrack } from '../DTO/addTrack.dto';
import { IAddArtist } from '../DTO/addArtist.dto';

const { ENV_URL } = URL;

// запрашиваем ссылку для аудиоплеера для воспроизведения
const getSong = async (id: number): Promise<string> => {
	try {
		const {
			data: { message: songLink },
		} = await axios.get(`${ENV_URL}/api/song/link/${id}`);
		return songLink;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// возвращаем текст какой-то песни
const getTextSong = async (id: string) => {
	try {
		const {
			data: { message: textSong },
		}: AxiosResponse<{ message: string }> = await axios.get(`${ENV_URL}/api/song/text/${id}`);
		return textSong;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// загружаем трек в базу данных
const uploadTrack = async ({
	ourname,
	originalname,
	tempo,
	filetoupload,
	textsong,
	artistid,
	isChristmas,
}: IAddTrack) => {
	const formData = new FormData();
	formData.append('our_name', ourname);
	formData.append('original_name', originalname);
	formData.append('tempo', tempo);
	formData.append('track', filetoupload);
	formData.append('text_song', textsong);
	formData.append('artist_id', String(artistid.id));
	formData.append('is_christmas', String(isChristmas));

	try {
		const { data } = await axios.post(`${ENV_URL}/api/song/create`, formData, {});
		console.log(data);
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// Добавляем нового артиста в базу
//TODO: Добавить конкретику и использовать уже параметры
const createArtist = async ({ name, description }: IAddArtist) => {
	try {
		await axios.post(`${ENV_URL}/api/artist/create`, {
			name,
			description,
		});
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// получаем всех артистов для формы загрузки
const getArtists = async () => {
	try {
		const {
			data: { message: artists },
		}: AxiosResponse<{ message: IArtist[] }> = await axios.get(`${ENV_URL}/api/artist/all`);
		return artists;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// возвращаем массив песен с добавленным индексом для возможности проверки следующего/предыдущего треков
const getMusicTable = async () => {
	try {
		const {
			data: { message: songs },
		}: AxiosResponse<{ message: ISong[] }> = await axios.get(`${ENV_URL}/api/song/all`);
		const songsArr = songs.map((song, index) => ({ ...song, index }));
		return songsArr;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// ищем песни с заданным значением
const findManySongs = async (value: string) => {
	try {
		const {
			data: { message: findedSongs },
		}: AxiosResponse<{ message: ISong[] | null }> = await axios.post(`${ENV_URL}/api/song/find`, {
			find: `${value}`,
		});
		return findedSongs;
	} catch (e) {
		throw new Error(handleError(e));
	}
};

// возвращаем список рождественских песен
const getChristmasSongs = async () => {
	try {
		const {
			data: { message: songs },
		}: AxiosResponse<{ message: ISong[] }> = await axios.get(`${ENV_URL}/api/song/christmas`);
		return songs.map((song, index) => ({ ...song, index }));
	} catch (e) {
		throw new Error(handleError(e));
	}
};

export interface IUpdateLyricsDTO {
	id: string;
	text_song: string;
}

// обновляем текст песен
const updateLyrics = async({
	id,
	text_song,
}: IUpdateLyricsDTO) => {
	try {
		const formData = new FormData();
		formData.append('id', id);
		formData.append('text_song', text_song);

		await axios.post(`${ENV_URL}/api/song/update/text`, formData);
	} catch (e) {
		throw new Error(handleError(e));
	}
};

export {
	getMusicTable,
	getSong,
	getTextSong,
	createArtist,
	getArtists,
	findManySongs,
	uploadTrack,
	getChristmasSongs,
	updateLyrics,
};
