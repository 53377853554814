import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import allRoutes from './routes';
import { getFeatures } from '@services/api/feature';
import { rawMenu } from '@services/menu-builder';

/**
 *
 * Роутер приложения, содержит приватные и публичные маршруты
 * по пути /js/assets/router/routes
 *
 */
const router = createRouter({
	history: createWebHistory(),
	routes: [
		// {
		// 	path: '/',
		// 	redirect: '/education',
		// },
		...allRoutes,
	],
	scrollBehavior(to, _, savedPosition) {
		if (to.path.includes('education')) {
			return new Promise((resolve) => {
				setTimeout(() => {
					if (savedPosition) {
						resolve({ ...savedPosition, behavior: 'smooth' });
					} else {
						resolve({ left: 0, top: 0 });
					}
				}, 500);
			});
		} else {
			return { top: 0 };
		}
	},
});

router.beforeEach(async (to, _, next) => {
	// Check if the user is authenticated
	await store.dispatch('checkAuth');

	const auth = store.state.auth.authenticated;

	const publicRoute = to.matched.some((record) => record.meta.public);

	if (!publicRoute && !auth) {
		return next('/auth');
	} else {
		if (!store.state.sidebarMenu.sidebarMenu && auth) {
			const rawSidebarMenu = await getFeatures();
			await store.dispatch('setSidebarMenu', rawSidebarMenu);

			if (auth && to.fullPath === '/') {
				const mainRoute = rawMenu.items.find(rawItem => rawItem.serverName === rawSidebarMenu.main);
				return next(mainRoute.to);
			}
		}
		next();
	}
});

export default router;
