import handleError from '../../utils/errorHandler';
import { getMusicTable, getTextSong, findManySongs } from '@services/api/musicTable/dataHandler';
import { IStoreMusicTable } from '../interfaces/storeMusicTable.interface';
import { ISong } from '@interfaces/Models/Song.interface';

interface IRewindTracks {
	current: ISong;
	newSongs: ISong[];
	playerButton: boolean;
	isPlaying: boolean;
}

const storeMusicTable = {
	state: {
		songs: [],
		tempSongs: [],
		current: {},
		isPlaying: false,
		playerButton: true,
		textSong: { originalName: '', lyrics: '', },
		loading: false,
	},
	mutations: {
		SET_SONGS: (state: IStoreMusicTable, songs: ISong[]) => {
			state.songs = songs;
		},
		SET_TEMP_SONGS: (state: IStoreMusicTable, tempSongs: ISong[]) => {
			state.tempSongs = tempSongs;
		},
		SET_SONGS_LOADING: (state: IStoreMusicTable, loading: boolean) => {
			state.loading = loading;
		},
		SET_CURRENT_SONG: (state: IStoreMusicTable, song: ISong) => {
			state.current = song;
		},
		SET_IS_PLAYING: (state: IStoreMusicTable, isPlaying: boolean) => {
			state.isPlaying = isPlaying;
		},
		SET_PLAYER_BUTTON: (state: IStoreMusicTable, bool: boolean) => {
			state.playerButton = bool;
		},
		FIND_MANY_SONGS: (state: IStoreMusicTable, songs: ISong[]) => {
			state.songs = songs;
		},
		SET_TEXT_SONG:
			(state: IStoreMusicTable,
				{ textSong, artistName, artistImageUrl, originalName, ourName, tempo, id }: any
			) => {
				state.textSong = {
					...state.textSong,
					id,
					lyrics: textSong,
					artistName,
					cover: artistImageUrl,
					originalName,
					ourName,
					tempo,
				};
			},
		UPDATE_TEXT_SONG: (state: IStoreMusicTable, lyrics: string) => {
			console.log('UPDATED FROM COMMIT', lyrics);
			state.textSong = {
				...state.textSong,
				lyrics,
			};
		},
		SET_RESET_PLAYER: (state: IStoreMusicTable) => {
			state.isPlaying = false;
			state.current = {
				artistId: null,
				artistName: '',
				comment: '',
				id: null,
				ourName: '',
				tempo: null,
				originalName: '',
				index: null,
				artistImageUrl: null,
				multitrack_link: '',
			};
			state.songs = [];
			state.textSong = { lyrics: '', originalName: '', cover: '', artistName: '', ourName: '', tempo: null, id: null };
		},
	},
	actions: {
		setSongs: ({ commit }, songs: ISong[]) => commit('SET_SONGS', songs),
		setTempSongs: ({ commit }, tempSongs: ISong[]) => commit('SET_TEMP_SONGS', tempSongs),
		setSongsLoading: ({ commit }, loading: boolean) => commit('SET_SONGS_LOADING', loading),
		setCurrentSong: ({ commit }, song: ISong) => commit('SET_CURRENT_SONG', song),
		setIsPlaying: ({ commit }, isPlaying: boolean) => commit('SET_IS_PLAYING', isPlaying),
		setPlayerButton: ({ commit }, bool: boolean) => commit('SET_PLAYER_BUTTON', bool),
		setResetPlayer: ({ commit }) => commit('SET_RESET_PLAYER'),
		getSongs: async ({ commit }) => {
			try {
				await commit('SET_SONGS_LOADING', true);
				const songs = await getMusicTable();
				await commit('SET_SONGS', songs);
				await commit('SET_CURRENT_SONG', songs[0]);
				await commit('SET_SONGS_LOADING', false);
			} catch (e) {
				await commit('SET_SONGS_LOADING', false);
				await commit('SET_SONGS', []);
			}
		},
		refreshSongs: async ({ commit }) => {
			try {
				await commit('SET_SONGS_LOADING', true);
				const songs = await getMusicTable();
				await commit('SET_SONGS', songs);
				await commit('SET_SONGS_LOADING', false);
			} catch (e) {
				await commit('SET_SONGS_LOADING', false);
				throw new Error(handleError(e));
			}
		},
		findManySongs: async ({ commit }, value: string) => {
			try {
				await commit('SET_SONGS_LOADING', true);
				const songs = await findManySongs(value);

				if (songs) {
					await commit('SET_SONGS', songs);
				} else {
					await commit('SET_SONGS', []);
				}

				await commit('SET_SONGS_LOADING', false);
			} catch (e) {
				await commit('SET_SONGS', []);
				await commit('SET_SONGS_LOADING', false);
				// throw new handleError(e);
			}
		},
		setRewind: ({ commit }, { current, newSongs, playerButton, isPlaying }: IRewindTracks) => {
			commit('SET_CURRENT_SONG', current);
			commit('SET_SONGS', newSongs);
			commit('SET_PLAYER_BUTTON', playerButton);
			commit('SET_IS_PLAYING', isPlaying);
		},
		setTextSong: async ({ commit }, id: number) => {
			try {
				const textMeta = await getTextSong(String(id));
				commit('SET_TEXT_SONG', textMeta);
			} catch (e) {
				commit('SET_TEXT_SONG', '');
				console.log('ERROR');
				throw new Error(handleError(e));
			}
		},
		updateTextSong: ({ commit }, lyrics: string) => {
			console.log('state updated', lyrics);
			commit('UPDATE_TEXT_SONG', lyrics);
		}
	},
	modules: {},
};

export default storeMusicTable;
