import { auth_en, auth_ru } from './auth';
import { ed_page_en, ed_page_ru } from './educationalPage';
import { class_page_en, class_page_ru } from './classPage';
import { info_messages_en, info_messages_ru } from './infoMessages';
import { error_page_en, error_page_ru } from './errorPage';
import { main_page_en, main_page_ru } from './mainPage';

export const messages = {
	ru: {
		// главная страница портала
		mainpage: {
			...main_page_ru,
		},
		// страница авторизации и регистрации
		auth: {
			...auth_ru,
		},
		infoMessages: {
			...info_messages_ru,
		},
		educationPage: {
			...ed_page_ru,
		},
		classPage: {
			...class_page_ru,
		},
		errorPage: {
			...error_page_ru,
		},
		role: {
			admin: 'Администратор',
			user: 'Пользователь',
			unknown: 'Unknown',
		},
	},
	en: {
		mainpage: {
			...main_page_en,
		},
		auth: {
			...auth_en,
		},
		infoMessages: {
			...info_messages_en,
		},
		educationPage: {
			...ed_page_en,
		},
		classPage: {
			...class_page_en,
		},
		errorPage: {
			...error_page_en,
		},
		role: {
			admin: 'Administrator',
			user: 'User',
			unknown: 'Unknown',
		},
	},
} as const;

