import { IRolesFunctions } from '.';

export class Admin implements IRolesFunctions {
	showEditLyrics(): boolean {
		return true;
	}
	showEducationAdmin(): boolean {
		return true;
	}
	showAddArtist(): boolean {
		return true;
	}
	showAddTrack(): boolean {
		return true;
	}
	showAddEvents(): boolean {
		return true;
	}
}
