import { IStorePlayerState } from "@store/interfaces/storePlayerState.interface";

const storePlayerState = {
	state: {
		isSidebarPlayerOpened: false,
		playbackTime: 0,
		remainingTime: 0,
		duration: 0,
	},
	mutations: {
		SET_IS_SIDEBAR_PLAYER_OPENED: (state: IStorePlayerState, isSidebarPlayerOpened: boolean) => {
			state.isSidebarPlayerOpened = isSidebarPlayerOpened;
		},
		SET_PLAYBACK_TIME: (state: IStorePlayerState, playbackTime: number) => {
			state.playbackTime = playbackTime;
		},
		SET_REMAINING_TIME: (state: IStorePlayerState, remainingTime: number) => {
			state.remainingTime = remainingTime;
		},
		SET_DURATION: (state: IStorePlayerState, duration: number) => {
			state.duration = duration;
		},
	},
	actions: {
		setIsSidebarPlayerOpened: ({ commit }, isSidebarPlayerOpened: boolean) =>
			commit('SET_IS_SIDEBAR_PLAYER_OPENED', isSidebarPlayerOpened),
		setPlaybackTime: ({ commit }, playbackTime: number) =>
			commit('SET_PLAYBACK_TIME', playbackTime),
		setRemainingTime: ({ commit }, playbackTime: number) =>
			commit('SET_REMAINING_TIME', playbackTime),
		setDuration: ({ commit }, duration: number) =>
			commit('SET_DURATION', duration),
	},
	modules: {},
};

export default storePlayerState;
