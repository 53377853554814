import axios, { AxiosResponse } from 'axios';
import store from '@store/index';
import router from '@router/index';
import handleError from '@utils/errorHandler';
import { URL } from '@utils/innerENV';
import { removeDataState } from './musicTable/DataStateHandler';
import { IRegisterDTO } from './DTO/auth.dto';
import { getFeatures } from './feature';
import { rawMenu } from '@services/menu-builder';

const { ENV_URL } = URL;

const register = async ({
	first_name,
	last_name,
	email,
	// nickname,
	password,
	phone,
	church_id,
}: IRegisterDTO) => {
	await store.dispatch('setLoading', true);
	try {
		await axios.post(`${ENV_URL}/api/register`, {
			first_name,
			last_name,
			email,
			password,
			phone,
			church_id,
		});
		await store.dispatch('setAuth', false);
		await router.push('/auth');
	} catch (e) {
		await store.dispatch('setLoading', false);

		if (e.response && e.response.data && e.response.data.error) {
			throw new Error(e.response.data.error);
		} else {
			throw new Error('unknown error');
		}
	}
	await store.dispatch('setLoading', false);
};

const getUser = async () => {
	const user: AxiosResponse<{ name: string; status?: string }> = await axios.get(
		`${ENV_URL}/api/user`
	 );
	 return user;
};

const login = async (data: { email: string; password: string }) => {
	await store.dispatch('setLoading', true);
	try {
		await axios.post(`${ENV_URL}/api/login`, {
			email: `${data.email}`,
			password: `${data.password}`,
		}, {
			withCredentials: true,
		});
		const {
			data: { name },
		} = await getUser();
		await store.dispatch('setAuth', true);
		await store.dispatch('setUser', name);
		const rawSidebarMenu = await getFeatures();
		await store.dispatch('setSidebarMenu', rawSidebarMenu);

		const mainRoute = rawMenu.items.find(rawItem => rawItem.serverName === rawSidebarMenu.main);
		await router.push(mainRoute.to);
	} catch (e) {
		await store.dispatch('setLoading', false);
		throw new Error(handleError(e));
	}
	await store.dispatch('setLoading', false);
};

const logout = async () => {
	await store.dispatch('setLoading', true);
	await removeDataState();
	document.cookie = `_holy_staff_session=;expires=${new Date(0).toUTCString()}`;
	await store.dispatch('setLoading', false);
	await router.push('/auth');
};

const checkRole = async () => {
	const data = await axios.get(`${ENV_URL}/api/role`);
	return data.status;
};

const restorePassword = async (email: string) => {
	try {
		const {
			data: { message },
		} = await axios.post<{ message: string }>(`${ENV_URL}/api/user/restore`, {
			email,
		});
		return message;
	} catch (e) {
		throw new Error(e);
	}
};

export { register, login, logout, getUser, checkRole, restorePassword };
