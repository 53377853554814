/**
 * Глобальная переменная для определения адреса домена
 * приложения. Нужна для девопса сервиса. Изменять не требуется.
 *
 */
const URL = {
	ENV_URL: `${process.env.VUE_SCHEME}://${process.env.VUE_ADDR}`,
};

const ENV: { [key: string]: string } = {
	EDUCATION_LABEL: 'Образование',
	EDUCATION_URL: '/education',
};

export { URL, ENV };
