export const class_page_ru = {
	accessDenied: 'Доступ к курсу запрещен',
	lessonNotFound: 'Урок не найден',
	courseNotFound: 'Курс не найден',
	navigation: 'Навигация',
	toTheCourseContent: 'Вернуться к курсу',
} as const;

export const class_page_en = {
	accessDenied: 'Access to the course denied',
	lessonNotFound: 'The lesson is not found',
	courseNotFound: 'The course is not found',
	navigation: 'Navigation',
	toTheCourseContent: 'Return to the course content',
} as const;