import { IStoreApp, LocalesType } from '../interfaces/storeApp.type';

const storeApp = {
	state: {
		locale: 'en',
	},
	mutations: {
		SET_LANGUAGE: (state: IStoreApp, language: LocalesType) => {
			state.locale = language;
		},
	},
	actions: {
		setLanguage: ({ commit }, language: LocalesType) => commit('SET_LANGUAGE', language),
	},
	modules: {},
};

export default storeApp;
