export const auth_ru = {
	login: 'Авторизация',
	create_account: 'Регистрация',
	userPlaceholder: 'Логин',
	passwordPlaceholder: 'Пароль',
	loginButton: 'Войти',
	FirstName: 'Имя',
	SecondName: 'Фамилия',
	Email: 'Электронная почта',
	NickName: 'Никнейм',
	IamAgree: 'Даю согласие на обработку персональных данных.',
	register: 'Зарегистрироваться',
	successAuth: 'Успешная авторизация',
	successReg: 'Успешная регистрация',
	selectChurchPlaceholder: 'Выберите церковь',
	forgotPass: 'Забыли пароль?',
	agreement_1: 'Регистрируясь, вы принимаете',
	agreement_2: 'условия пользовательского соглашения',
	agreement_3: 'и',
	agreement_4: 'соглашаетесь на обработку персональных данных',
	back: 'назад',
	signup: 'Зарегистрироваться',
	resetPasswordTitle: 'Сброс пароля',
	resetPasswordSubtitle:
    'Чтобы получить новый пароль, пожалуйста, введите адрес электронной почты',
	resetPasswordPlaceholder: 'Электронная почта',
	resetPasswordSendNew: 'Отправить новый пароль',
} as const;

export const auth_en = {
	login: 'Log In',
	create_account: 'Sign Up',
	userPlaceholder: 'Username',
	passwordPlaceholder: 'Password',
	loginButton: 'Sign in',
	FirstName: 'First Name',
	SecondName: 'Second Name',
	Email: 'Email',
	NickName: 'Nickname',
	IamAgree: 'I agree to the processing of personal data.',
	register: 'Register',
	successAuth: 'Successful authorization',
	successReg: 'Successful registration',
	selectChurchPlaceholder: 'Select a church',
	forgotPass: 'Need a password?',
	agreement_1: 'By registering, you',
	agreement_2: 'accept the terms of the user agreement',
	agreement_3: 'and',
	agreement_4: 'consent to the processing of personal data',
	back: 'back',
	signup: 'Register',
	resetPasswordTitle: 'Password reset',
	resetPasswordSubtitle:
    'Enter an email address to receive a code that can be used to set your password',
	resetPasswordPlaceholder: 'Email',
	resetPasswordSendNew: 'Send code',
} as const;