import store from '../../../store';

/*
Уничтожаем данные о стейте
при выходе из приложения
*/
const removeDataState = async () => {
	await store.dispatch('setResetPlayer');
	await store.dispatch('setResetAuth');
};

export { removeDataState };
