import { IEvent } from '@interfaces/Models/Event.interface';
import { getEvents } from '@services/api/scheduleHandler';
import { IStoreSchedule } from '../interfaces/storeSchedule.interface';

const storeSchedule = {
	state: {
		events: [],
		error: false,
		loading: false,
	},
	mutations: {
		SET_EVENTS: (state: IStoreSchedule, events: IEvent[]) => {
			state.events = events;
		},
		SET_EVENTS_LOADING: (state: IStoreSchedule, loading: boolean) => {
			state.loading = loading;
		},
		SET_EVENTS_ERROR: (state: IStoreSchedule, error: boolean) => {
			state.error = error;
		},
	},
	actions: {
		setEvents: ({ commit }, events: IEvent[]) => commit('SET_EVENTS', events),
		getEvents: async ({ commit }) => {
			try {
				await commit('SET_EVENTS_ERROR', false);
				await commit('SET_EVENTS_LOADING', true);
				const events = await getEvents();
				await commit('SET_EVENTS', events);
				await commit('SET_EVENTS_LOADING', false);
			} catch (e) {
				await commit('SET_EVENTS_ERROR', true);
				await commit('SET_EVENTS_LOADING', false);
			}
		},
	},
	modules: {},
};

export default storeSchedule;
