export const info_messages_ru = {
	loginSuccess: 'Добро пожаловать!',
	loginFailed: 'Неверный логин или пароль',
	logout: 'До новых встреч!',
	checkFields: 'Заполните, пожалуйста все поля формы',
	signInFailed: 'Пожалуйста, проверьте правильно ли вы заполнили поля формы',
	incorrectEmail: 'Пожалуйста, введите правильный email',
	unknownError: 'Произошла неизвестная ошибка',
	emailExists: 'Email уже существует',
	newPasswordSent: 'Ваш новый пароль успешно отправлен',
	WrongEmail: 'Пожалуйста, введите корректный email',
	wrongPhone: 'Пожалуйста, введите корректный телефон',
} as const;

export const info_messages_en = {
	loginSuccess: 'Welcome again!',
	loginFailed: 'Invalid login or password.',
	logout: 'Good bye! Have a good day!',
	checkFields: 'Please fill out all the fields in the form',
	signInFailed: 'Please check if you have filled in the form fields correctly',
	incorrectEmail: 'Please enter a valid email address',
	unknownError: 'An unknown error occurred',
	emailExists: 'Email already exists',
	newPasswordSent: 'Your new password was successfully sent. Please, check your email box',
	WrongEmail: 'Please enter a correct email',
} as const;