export const ed_page_ru = {
	form1: 'задания',
	form2: 'заданий',
	confirmTitle: 'Подтверждение',
	confirmEssay:
    'Это задание на ограниченное количество времени. Вы готовы начать? При нажатии на "ОК" начнется отсчет',
	confirmCancel: 'Отменить',
	confirmOK: 'ОК',
} as const;

export const ed_page_en = {
	form1: 'done',
	confirmTitle: 'Confirmation',
	confirmEssay:
    'This is a timed assignment. Are you ready to begin? When you click OK, the countdown will start',
	confirmCancel: 'Cancel',
	confirmOK: 'OK',
} as const;