export const main_page_ru = {
	header: 'Гибкая система, помогающая объединить потребности вашей церкви в одном месте',
	subheader:
    'Набор программных инструментов, которые систематизируют информацию и помогают поддерживать связь с общиной',
	login: 'Авторизация',
	signup: 'Регистрация',
} as const;

export const main_page_en = {
	header: 'A flexible system that helps to combine the needs of your church in one place',
	subheader:
    'Holystaff a set of software tools that systematize information and help keep in touch with the community',
	login: 'Log in',
	signup: 'Sign up',
} as const;