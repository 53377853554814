import { AxiosError } from 'axios';
import { errors } from './messages';

/**
 *
 * Функция перехвата ошибок. Если существует ошибка в объекте messages.js,
 * то она будет возвращена. Иначе будет возвращена стандартная ошибка
 *
 * Пример применения:
 * ```ts
 * throw new Error(handleError(e))
 * ```
 * @param e
 * @returns Расшифрованная ошибка согласно массиву ошибок или сама ошибка, если она отсутствует в массиве
 */
const handleError = (e: AxiosError) => {
	if (errors[e.response.data.error]) {
		throw new Error(errors[e.response.data.error]);
	} else {
		throw new Error(e.message);
	}
};

export default handleError;
