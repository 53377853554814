import { RouteRecordRaw } from 'vue-router';
import { ENV } from "@utils/innerENV";

// const Home = () => import('../../components/Home.vue');
const MusicTable = () => import('@pages/Music/MusicTable.vue');
const EventsTimeline = () => import('@pages/EventsTimeline.vue');
const PageNotFound = () => import('@pages/NotFound.vue');

// EDUCATION BLOCK
const AdminMain = () => import('@pages/Education/EducationAdmin.vue');
const StudentsListPage = () => import('@pages/Education/EducationAdminStudentPage.vue');
const EducationMain = () => import('@pages/Education/EducationMain.vue');
const CourseMain = () => import('@pages/Education/CourseMain.vue');
const Lesson = () => import('@pages/Education/Lesson.vue');
const App = () => import('../../App.vue');

const privateRoutes: RouteRecordRaw[] = [
	{
		path: '/',
		component: App,
		children: [
			{
				path: '/dashboard',
				component: MusicTable,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: 'Медиатека' }] },
			},
			{
				path: '/events',
				component: EventsTimeline,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: 'События' }] },
			},
			{
				path: `${ENV.EDUCATION_URL}/teach`,
				component: AdminMain,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: ENV.EDUCATION_LABEL }] },
			},
			{
				path: `${ENV.EDUCATION_URL}/teach/:id`,
				component: StudentsListPage,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: ENV.EDUCATION_LABEL }] },
			},
			{
				path: ENV.EDUCATION_URL,
				component: EducationMain,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: ENV.EDUCATION_LABEL }] },
			},
			{
				path: `${ENV.EDUCATION_URL}/:id`,
				component: CourseMain,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: ENV.EDUCATION_LABEL }] },
			},
			{
				path: `${ENV.EDUCATION_URL}/:id/:lessonid`,
				component: Lesson,
				meta: { breadcrumb: [{ parent: 'Панель управления', label: ENV.EDUCATION_LABEL }] },
			},
		],
	},
	{ path: '/:pathMatch(.*)*', component: PageNotFound },
];

/**
 * Здесь содержатся массив приватных роутов, по которым можно пройти только
 * в случае успешной авторизации пользователя
 *
 */
const exportedRoutes: RouteRecordRaw[] = privateRoutes.map((route) => ({
	...route,
	meta: { ...route.meta, public: false },
}));

export default exportedRoutes;
