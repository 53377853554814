import { Admin } from './Admin';
import { MusicEditor } from './MusicEditor';
import { NotUser } from './NotUser';
import { User } from './User';

export interface IRolesFunctions {
  showAddTrack(): boolean;
  showAddArtist(): boolean;
  showAddEvents(): boolean;
	showEducationAdmin(): boolean;
	showEditLyrics(): boolean;
}

const rolesMap = {
	user: User,
	admin: Admin,
	musicEditor: MusicEditor,
	notUser: NotUser,
};

export type RolesKeys = keyof typeof rolesMap;
type roleTypes = typeof rolesMap[RolesKeys];
type ExtractInstanceType<T> = T extends new () => infer R ? R : never;

class RolesFactory {
	/**
   * Возвращает конкретную роль по параметру k из ролей пользователя
   *
   * @param k
   * @returns конкретная роль пользователя согласно параметру
   */
	static getRole(k: RolesKeys): ExtractInstanceType<roleTypes> {
		return new rolesMap[k]();
	}
}

/**
 * Сервис для распределения функционала по ролям.
 * Требует добавления одной из разрешенных ролей,
 * которые перечислены в переменной rolesMap.
 * 
 * предоставляется в **application.js**. 
 * инджектится в конкретном месте по необходимости проверки доступа
 * ```ts
 * const _rolesService: RolesService = inject('rolesService');
 * ```
 * 
 * Пример применения:
 * ```ts
 * new RolesService().getRole('user').showAddTrack()
 * ```
 */
export class RolesService {
	getShowAddTracksByRole(user: RolesKeys) {
		return RolesFactory.getRole(user).showAddTrack();
	}

	getShowAddEventsByRole(user: RolesKeys) {
		return RolesFactory.getRole(user).showAddEvents();
	}

	getShowAddArtistByRole(user: RolesKeys) {
		return RolesFactory.getRole(user).showAddArtist();
	}

	getShowEducationAdmin(user: RolesKeys) {
		return RolesFactory.getRole(user).showEducationAdmin();
	}

	getShowEditLyrics(user: RolesKeys) {
		return RolesFactory.getRole(user).showEditLyrics();
	}
}
